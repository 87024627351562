// @ts-check
/**
 * @fileoverview custom interval input
 */

import {arrayOf, bool, func, number, object, string} from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useFormState} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {DateContext} from "../../../contexts/dates";
import usePrevious from "../../../hooks/usePrevious";
import {ControlledSelectorSingle} from "../controlled_form";
import {WeekRow} from "./components/week_row";
import useStyles from "./custom_interval_new.styles";

const numberArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((num) => ({label: num, value: num}));
const WEEK_DAYS = 7;

/**
 * render CustomIntervalNew component
 *
 * @param {object} props
 * @param {string} props.selectedWeeks
 * @param {object} props.control
 * @param {boolean} [props.disabled]
 * @param {Array<number>} props.frequency
 * @param {Function} props.onChangeFrequency
 * @param {DateTimeType} props.startDate
 * @param {boolean} [props.isWeekendVisible]
 * @return {React.ReactElement}
 */
export const CustomIntervalNew = ({selectedWeeks, control, disabled, frequency, onChangeFrequency, startDate, isWeekendVisible}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    const {getDT} = useContext(DateContext);
    const {errors} = useFormState({control});

    const prevValue = usePrevious(startDate);
    const [isoWeekday, setIsoWeekday] = useState(getDT(startDate, "weekday"));
    const prevIsoWeekday = usePrevious(isoWeekday);

    // filter only repeat items less than repeatAfterDays (number * WEEK_DAYS)
    useEffect(() => {
        const newRepeat = frequency.filter((item) => item < parseInt(selectedWeeks) * WEEK_DAYS);
        onChangeFrequency(newRepeat);
    }, [selectedWeeks]);

    useEffect(() => {
        // if start date is changed, reset repeat days
        if (startDate.isValid) {
            setIsoWeekday(getDT(startDate, "weekday"));
        }
        if (
            prevValue &&
            startDate &&
            prevValue.isValid &&
            startDate.isValid &&
            getDT(prevValue, "weekday") !== getDT(startDate, "weekday") &&
            frequency.length > 0
        ) {
            onChangeFrequency([]);
        }
    }, [startDate]);

    return (
        <div className={classes.fullWidth}>
            <ControlledSelectorSingle
                classesStyle={classes.fullWidth}
                control={control}
                items={numberArray}
                name="customWeeks"
                title={t("App.weeks")}
            />
            <div className={classes.weekRowWrapper}>
                <WeekRow
                    disabled={disabled}
                    frequency={frequency}
                    hasFrequencyError={!!errors?.frequency}
                    isWeekendVisible={isWeekendVisible}
                    prevIsoWeekday={prevIsoWeekday}
                    selectedWeeks={selectedWeeks}
                    startDate={startDate}
                    onChangeFrequency={onChangeFrequency}
                />
            </div>
        </div>
    );
};

CustomIntervalNew.propTypes = {
    disabled: bool,
    selectedWeeks: string.isRequired,
    control: object.isRequired,
    frequency: arrayOf(number),
    onChangeFrequency: func.isRequired,
    startDate: object.isRequired, // DateTime object
    isWeekendVisible: bool
};

CustomIntervalNew.defaultProps = {
    classes: {}
};
