/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const AvailableIcon = ({size, className}) => {
    return (
        <SvgIcon style={{fontSize: size}} className={className} viewBox="0 0 24 24">
            <path id="Pfad_6477" data-name="Pfad 6477" d="M0,0H24V24H0Z" fill="none" />
            <path
                id="Pfad_6478"
                data-name="Pfad 6478"
                d="M16.53,11.06,15.47,10l-4.88,4.88L8.47,12.76,7.41,13.82,10.59,17l5.94-5.94ZM19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                fill="#616161"
            />
        </SvgIcon>
    );
};

AvailableIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

AvailableIcon.defaultProps = {
    size: "1rem"
};

export default AvailableIcon;
