// @ts-check
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {arrayOf, number, string} from "prop-types";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "./date_conflict_list.styles";

/**
 * The list on conflicting dates in the TimeslotDialog component
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {number} [props.limit=5]
 * @param {Array<string>} props.list The list of conflicting dates
 * @return {React.ReactElement}
 */
const DateConflictList = ({className, limit = 5, list}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    // state
    const [allVisible, setAllVisible] = useState(false);

    const handleShowAll = () => setAllVisible(true);

    const maxLength = allVisible ? list.length : limit;

    return (
        <div className={className}>
            <ul className={cx(classes.list, {[classes.scrollableList]: allVisible})}>
                {list.slice(0, maxLength).map((date) => (
                    <li className={classes.dateItem} key={date}>
                        {formatFromISO(date, DATE_FORMATS.DATE)}
                    </li>
                ))}
            </ul>
            {list.length > 5 && !allVisible && (
                <Button className={classes.moreButton} color="primary" endIcon={<ExpandMoreIcon />} size="small" onClick={handleShowAll}>
                    {t("DateConflictList.showAll")}
                </Button>
            )}
        </div>
    );
};

DateConflictList.propTypes = {
    className: string,
    limit: number,
    list: arrayOf(string).isRequired
};

DateConflictList.defaultProps = {
    limit: 5
};

export default DateConflictList;
