import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    subheader: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "1.125rem",
        marginBottom: "1.875rem",
        cursor: "pointer"
    },
    opened: {
        marginBottom: "1.875rem" // 23px
    },
    iconWrapper: {
        display: "flex"
    },
    icon: {
        fontSize: "1.5rem"
    },
    savedIcon: {
        margin: "0 0.625rem",
        fontSize: "1.5rem"
    },
    unvisible: {
        display: "none"
    }
}));
