// @ts-check
import {SvgIcon} from "@mui/material";
import {string} from "prop-types";
import React from "react";

import useStyles from "./op_overlap_icon.styles";

/**
 * OpOverlapIcon
 *
 * @param {object} props
 * @param {string} [props.style]
 * @return {JSX.Element}
 */
const OpOverlapIcon = ({style}) => {
    const {classes, cx} = useStyles();
    return (
        <SvgIcon className={cx(classes.root, style)} fill="none" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.89474 0H0.842105C0.61897 0.000719421 0.405164 0.0967294 0.247383 0.267061C0.089602 0.437393 0.000666411 0.668206 0 0.909091V7.27273H0.842105V0.909091H5.89474V0ZM7.1579 1.81818H2.52632C2.30318 1.8189 2.08938 1.91491 1.93159 2.08524C1.77381 2.25558 1.68488 2.48639 1.68421 2.72727V9.09091C1.68488 9.33179 1.77381 9.56261 1.93159 9.73294C2.08938 9.90327 2.30318 9.99928 2.52632 10H7.1579C7.38103 9.99928 7.59484 9.90327 7.75262 9.73294C7.9104 9.56261 7.99933 9.33179 8 9.09091V2.72727C7.99933 2.48639 7.9104 2.25558 7.75262 2.08524C7.59484 1.91491 7.38103 1.8189 7.1579 1.81818Z"
                fill="#BE4517"
            />
        </SvgIcon>
    );
};

OpOverlapIcon.propTypes = {
    style: string
};

export default OpOverlapIcon;
