import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    list: {
        listStyleType: "none",
        margin: 0,
        padding: 0
    },
    scrollableList: {
        maxHeight: "15rem",
        overflowY: "scroll"
    },
    dateItem: {
        "color": theme.palette.error.main,
        "fontSize": "0.75rem",
        "marginTop": theme.spacing("xxs"),

        "&:first-of-type": {
            marginTop: 0
        }
    },
    moreButton: {
        marginTop: theme.spacing("m"),
        padding: 0,
        fontSize: "0.75rem",
        textTransform: "none"
    }
}));
