import {AddCircleOutline, DoneAll, RemoveCircleOutline} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, {useState} from "react";

import useStyles from "./info_block.styles";

const InfoBlock = ({title, children, saved, defaultOpen, onToggle, keepMounted}) => {
    const [open, setOpen] = useState(Boolean(defaultOpen));
    const {classes, cx} = useStyles();

    const handleClick = () => {
        if (typeof onToggle === "function") {
            onToggle(!open);
        }
        setOpen(!open);
    };
    return (
        <div>
            <div
                className={cx(classes.subheader, {
                    [classes.opened]: open
                })}
                role={"button"}
                tabIndex={0}
                onClick={handleClick}
                onKeyDown={(e) => e.key === "Enter" && handleClick()}
            >
                {title}
                <div className={classes.iconWrapper} data-testid="infoBlockButton">
                    {saved && <DoneAll className={classes.savedIcon} color="primary" size="small" />}
                    {open ? (
                        <RemoveCircleOutline className={classes.icon} color="primary" size="small" />
                    ) : (
                        <AddCircleOutline className={classes.icon} color="primary" size="small" />
                    )}
                </div>
            </div>
            {keepMounted && (
                <div
                    className={cx({
                        [classes.unvisible]: !open
                    })}
                >
                    {children}
                </div>
            )}
            {!keepMounted && open && children}
        </div>
    );
};
InfoBlock.propTypes = {
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    children: PropTypes.node,
    saved: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    keepMounted: PropTypes.bool
};

export default InfoBlock;
