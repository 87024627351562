import {Close, Search} from "@mui/icons-material";
import {InputAdornment, TextField} from "@mui/material";
import {func, string} from "prop-types";
import React from "react";

import useStyles from "./menu_search_text_field.styles";

/**
 * Search text filed component used as a MenuItem inside a Select or just as a MenuItem
 * @param {Object} props
 * @param {String} props.value
 * @param {Function} props.onChange
 * @param {String} props.placeholder
 * @return {React.ReactElement}
 */
const MenuSearchTextField = ({value, onChange, placeholder}) => {
    const {classes} = useStyles();

    /**
     * stop firing MenuItem event if TextField is clicked/entered
     * @param {object} e - event object
     */
    const handleKeyDown = (e) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
                break;
            default:
                e.stopPropagation();
        }
    };

    const handleChange = (e) => {
        onChange(e.target.value);
    };
    return (
        <TextField
            className={classes.searchTextWrapper}
            InputLabelProps={{shrink: true}}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search color="primary" fontSize="small" />
                    </InputAdornment>
                ),
                endAdornment: value && (
                    <InputAdornment position="end">
                        <Close fontSize="small" style={{cursor: "pointer"}} onClick={(e) => onChange("")} />
                    </InputAdornment>
                ),
                disableUnderline: true
            }}
            inputProps={{"className": classes.searchInput, "data-testid": "searchTextField"}}
            name="searchTextField"
            placeholder={placeholder}
            value={value}
            variant="standard"
            onChange={(e) => handleChange(e)}
            onKeyDown={handleKeyDown}
        />
    );
};

MenuSearchTextField.propTypes = {
    value: string,
    onChange: func.isRequired,
    placeholder: string
};

export default MenuSearchTextField;
