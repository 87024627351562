/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const DoneIcon = ({size, className}) => {
    return (
        <SvgIcon style={{fontSize: size}} className={className}>
            <path
                fill="currentColor"
                d="M9,16.2 L5.5,12.7 C5.11,12.31 4.49,12.31 4.1,12.7 C3.71,13.09 3.71,13.71 4.1,14.1 L8.29,18.29 C8.68,18.68 9.31,18.68 9.7,18.29 L20.3,7.7 C20.69,7.31 20.69,6.69 20.3,6.3 C19.91,5.91 19.29,5.91 18.9,6.3 L9,16.2 Z"
            ></path>
        </SvgIcon>
    );
};

DoneIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

DoneIcon.defaultProps = {
    size: "1rem"
};

export default DoneIcon;
