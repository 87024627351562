// @ts-check
/**
 * @fileoverview gender component
 */

import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

const GENDER = {
    male: "male",
    female: "female",
    other: "other",
    unknown: "unknown"
};

/**
 * render Gender component
 * @param {Object} props
 * @param {NextOrGender} [props.gender]
 * @return {React.ReactElement}
 */
const Gender = ({gender}) => {
    const {t} = useTranslation();
    const genderText = Object.keys(GENDER).includes(gender) ? t(`GenderShort.${gender}`) : "-";
    return <span style={{paddingLeft: "0.25rem"}}>{genderText}</span>;
};

Gender.propTypes = {
    gender: PropTypes.oneOf([GENDER.male, GENDER.female, GENDER.other, GENDER.unknown])
};

export default Gender;
export {GENDER};
