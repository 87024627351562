import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    weekRowWrapper: {marginTop: "1rem", width: "100%"},
    fullWidth: {
        width: "100%"
    },
    weekRow: {
        display: "flex",
        padding: "0.5rem 0",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.grey[100]}`
    },
    weekLabel: {
        fontSize: "0.75rem",
        color: theme.palette.grey[700],
        lineHeight: "2rem",
        width: "6rem"
    },
    error: {
        color: theme.palette.error.main
    }
}));
