// @ts-check
import {DoneAll, Info, Warning} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./message.styles";

/**
 *
 * @param {object} props
 * @param {string} props.message
 * @param {AlertColor} [props.severity = "success"]
 * @param {Function} [props.onClose]
 * @return {React.ReactElement}
 */
const Message = ({message, severity = "success", onClose}) => {
    const {classes} = useStyles();
    const [open, setOpen] = React.useState(true);

    // icons
    const icons = {
        error: <Warning color={"inherit"} />,
        success: <DoneAll color={"inherit"} />,
        info: <Info color={"inherit"} />
    };
    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            autoHideDuration={6000}
            className={classes.root}
            open={open}
            onClose={handleClose}
        >
            <MuiAlert
                className={classes[severity]}
                color={severity}
                data-testid={`alert-${severity}`}
                elevation={6}
                icon={icons[severity]}
                severity={severity}
                onClose={handleClose}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

Message.propTypes = {
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(["error", "success", "info", "warning"]),
    onClose: PropTypes.func
};

Message.defaultProps = {
    severity: "success"
};

export default Message;
