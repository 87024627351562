// @ts-check

import cloneDeep from "lodash/cloneDeep";
import {array, bool, func, number, object, string} from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";

import {DateContext} from "../../../../contexts/dates";
import useStyles from "../custom_interval_new.styles";
import {DayButton} from "./day_button";

const WEEK_DAYS = 7;

/**
 *
 * @param {object} props
 * @param {boolean} [props.disabled]
 * @param {string} props.selectedWeeks
 * @param {DateTimeType} props.startDate
 * @param {number} [props.prevIsoWeekday]
 * @param {Array<number>} props.frequency
 * @param {boolean} [props.isWeekendVisible]
 * @param {Function} props.onChangeFrequency
 * @param {boolean} props.hasFrequencyError
 * @return {React.ReactElement}
 */
export const WeekRow = ({
    disabled,
    selectedWeeks,
    startDate,
    prevIsoWeekday,
    frequency,
    isWeekendVisible,
    onChangeFrequency,
    hasFrequencyError
}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {getDT} = useContext(DateContext);

    /**
     * Calculate number of selected days within interval (= frequency)
     * ex. Mon, Wed, Fri of the first week and Tue, Thu of second week within 2 weeks interval
     * => 0, 2, 4, 8, 10 will be returned
     * @param {number} i - number of week
     * @param {number} j -
     * @return {number}
     */
    const getAdjustedRepeatNumber = (i, j) => {
        const num = i * WEEK_DAYS + j;
        const isoWeekday = getDT(startDate, "weekday");
        const adjust = isoWeekday !== -1 ? isoWeekday - 1 : prevIsoWeekday; // 1: Monday, 2: Tuesday...
        return num - adjust < 0 ? num - adjust + parseInt(selectedWeeks) * WEEK_DAYS : num - adjust;
    };

    const handleClick = (i, j) => {
        const selectedDays = cloneDeep(frequency);
        const adjustedNum = getAdjustedRepeatNumber(i, j);
        if (selectedDays.includes(adjustedNum)) {
            selectedDays.splice(selectedDays.indexOf(adjustedNum), 1);
        } else {
            selectedDays.push(adjustedNum);
        }
        selectedDays.sort((a, b) => a - b);
        onChangeFrequency(selectedDays);
    };

    const dateSelect = [];
    for (let i = 0; i < parseInt(selectedWeeks); i++) {
        const dayButtons = [];
        for (let j = 0; j < WEEK_DAYS; j++) {
            const adjustedNum = getAdjustedRepeatNumber(i, j);
            if (!isWeekendVisible && j >= 5) {
                continue;
            } else {
                dayButtons.push(
                    <DayButton
                        adjustedNum={adjustedNum}
                        disabled={disabled}
                        frequency={frequency}
                        handleClick={handleClick}
                        i={i}
                        j={j}
                        key={`date-${i}+${j}`}
                    />
                );
            }
        }
        dateSelect.push(
            <div className={classes.weekRow} key={`week-${i}`}>
                <span
                    className={cx(classes.weekLabel, {
                        [classes.error]: hasFrequencyError
                    })}
                    data-testid="week-label"
                >
                    {t("App.nWeek", {num: i + 1})}
                </span>
                {dayButtons}
            </div>
        );
    }
    return <>{dateSelect}</>;
};
WeekRow.propTypes = {
    startDate: object.isRequired, // DateTime object
    selectedWeeks: string.isRequired,
    disabled: bool,
    frequency: array.isRequired,
    isWeekendVisible: bool,
    prevIsoWeekday: number,
    onChangeFrequency: func.isRequired,
    hasFrequencyError: bool
};
