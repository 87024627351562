// @ts-check
import {CancelRounded, Info} from "@mui/icons-material";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./info_dialog.styles";

/**
 * Render InfoDialog
 *
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Function} [props.onClose]
 * @param {"authError"|"connectionError"} [props.type="authError"]
 * @return {React.ReactElement}
 */
export const InfoDialog = ({open, onClose, type = "authError"}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const titleElement = (
        <Fragment>
            <Info className={classes.icon} color="primary" />
            {t("InfoDialog.title")}
        </Fragment>
    );
    const handleClose = (e) => {
        if (typeof onClose === "function") {
            onClose();
        }
    };

    let text = t("App.forceLogout");
    if (type === "connectionError") {
        text = t("App.internetConnectionFailure");
    }
    return (
        <Dialog
            open={open}
            PaperProps={{
                classes: {root: classes.root},
                id: "info-dialog"
            }}
            onClose={handleClose}
        >
            {typeof onClose === "function" && (
                <IconButton className={classes.iconButton} size="small" onClick={handleClose}>
                    <CancelRounded color="primary" />
                </IconButton>
            )}
            <DialogTitle className={classes.title}>{titleElement}</DialogTitle>
            <DialogContent>
                <div className={classes.message}>{text}</div>
            </DialogContent>
        </Dialog>
    );
};

InfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(["authError", "connectionError"])
};

export default InfoDialog;
