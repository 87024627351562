// @ts-check
import {RemoveCircleOutline} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./delete_confirmation.styles";

/** @typedef {("inherit"|"disabled"|"action"|"primary"|"secondary"|"error")} TriggerColor */
/**
 * @typedef {object} Trigger
 * @property {string} label
 * @property {TriggerColor} color
 * @property {boolean} [isRemoveIconVisible]
 */

/**
 * Render DeleteConfirmation component
 * @param {Object} props
 * @param {string} [props.children]
 * @param {Boolean} [props.disabled]
 * @param {String} [props.title]
 * @param {Trigger} props.trigger - setting for the label and icon that triggers to open DeleteConfirmation dialog
 * @param {Function} props.onConfirm
 * @return {React.ReactElement}
 */
const DeleteConfirmation = ({children, disabled, title, trigger, onConfirm}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (!disabled) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgree = () => {
        onConfirm();
        handleClose();
    };

    return (
        <Fragment>
            <div
                className={cx(classes.wrapper, {
                    [classes.disabled]: disabled
                })}
                data-testid="delete-button"
                role={"button"}
                tabIndex={0}
                onClick={handleClickOpen}
                onKeyDown={(e) => e.key === "Enter" && handleClickOpen()}
            >
                {trigger.label || t("DeleteConfirmation.title")}
                {trigger.isRemoveIconVisible && (
                    <RemoveCircleOutline
                        className={cx(classes.removeIcon, {
                            [classes.disabled]: disabled
                        })}
                        color={trigger.color}
                    />
                )}
            </div>
            <Dialog
                aria-describedby="delete-alert-dialog-description"
                aria-labelledby="delete-alert-dialog-title"
                maxWidth="md"
                open={open}
                PaperProps={{
                    elevation: 3
                }}
                onClose={handleClose}
            >
                <DialogTitle id="delete-alert-dialog-title">{title || t("DeleteConfirmation.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-alert-dialog-description">{children || t("DeleteConfirmation.text")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={handleClose}>
                        {t("DeleteConfirmation.disagree")}
                    </Button>
                    <Button color="primary" data-testid="confirmDeleteButtonPopUp" size="small" variant="contained" onClick={handleAgree}>
                        {t("DeleteConfirmation.agree")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

DeleteConfirmation.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    trigger: PropTypes.shape({
        color: PropTypes.string,
        label: PropTypes.string,
        isRemoveIconVisible: PropTypes.bool
    }),
    onConfirm: PropTypes.func.isRequired
};

DeleteConfirmation.defaultProps = {
    trigger: {
        color: "default",
        showIcon: true
    }
};

export default DeleteConfirmation;
