// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    root: {
        "width": "18px",
        "height": "18px",
        "borderRadius": "9px",
        "background": "#f7f7f7",
        "padding": "2px",
        "&:hover": {
            background: theme.palette.error.light
        }
    }
}));
