import {makeStyles} from "tss-react/mui";

/** @type {object} */
export default makeStyles()((theme) => ({
    root: {
        "position": "absolute",
        "backgroundColor": theme.custom.background.layer,
        "minWidth": "20rem",
        "padding": 0,
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent"
        },
        "& .MuiDialogActions-root": {
            padding: "1rem 2.5rem 2.5rem",
            justifyContent: "flex-start"
        },
        "& .MuiDialogContent-root": {
            overflowY: "visible",
            padding: "0 2.5rem"
        },
        "overflow": "visible"
    },
    isBlockscreenVisible: {
        zIndex: "1000 !important"
    }
}));
