import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        [theme.breakpoints.down("md")]: {
            width: "6.5rem",
            left: "calc(50% - 3.25rem)" // calc(50% - <width/2>)
        },
        [theme.breakpoints.up("sm")]: {
            width: "12rem",
            left: "calc(50% - 6rem)" // calc(50% - <width/2>)
        },

        "borderBottomRightRadius": "5px",
        "borderBottomLeftRadius": "5px",
        "zIndex": theme.zIndex.modal,
        "position": "fixed",
        "top": 0,
        "display": "flex",
        "alignItems": "center",
        "height": "2.5rem",
        "backgroundColor": theme.palette.primary.main,
        "padding": 0,
        "justifyContent": "space-between",
        "& .MuiIconButton-sizeSmall": {
            padding: 0
        }
    },
    rootWithTeaser: {
        top: "30px"
    },
    iconButton: {
        width: "1.5em"
    },
    icon: {
        color: theme.palette.primary.contrastText
    },
    iconDisabled: {
        color: theme.palette.grey[500]
    },
    disabled: {
        cursor: "default"
    },
    dateLabel: {
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: theme.spacing("m"),
        cursor: "pointer",
        whiteSpace: "nowrap"
    },
    popover: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    staticDatePicker: {
        height: "320px"
    },
    today: {
        color: theme.palette.primary.main,
        textTransform: "none",
        textDecoration: "underline",
        marginBottom: theme.spacing("s")
    },
    dayWrapper: {
        "position": "relative",
        "& button": {
            fontSize: "15px"
        }
    },
    todayDot: {
        width: "4px",
        height: "4px",
        borderRadius: "2px",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: "5px",
        left: "18px"
    },
    todayDotSelected: {
        backgroundColor: theme.palette.common.white
    }
}));
