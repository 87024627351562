import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    newPlanSaved: {
        "& .a": {
            fill: theme.palette.primary.main,
            opacity: 0.2
        },
        "& .b": {
            fill: "none",
            stroke: theme.palette.primary.main,
            strokeWidth: "3px"
        },
        "& .c": {
            fill: "none",
            stroke: theme.palette.primary.main,
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round"
        },
        "& .d": {
            stroke: "none"
        },
        "& .e": {
            fill: "none"
        }
    }
}));
