/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";


import useStyles from "./new_plan_icon.styles";

const NewPlanIcon = ({className}) => {
    const {classes, cx} = useStyles();
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            className={cx(classes.newPlan, className)}
            style={{}}
            viewBox="0 0 262 180"
            data-testid="new-plan-icon"
        >
            <g transform="translate(-472 -6262)">
                <rect className="a" width="262" height="180" transform="translate(472 6262)" />
                <g transform="translate(67 1707)">
                    <rect className="b" width="51" height="21" rx="4" transform="translate(493 4583)" />
                    <rect className="b" width="51" height="21" rx="4" transform="translate(556 4583)" />
                    <rect className="b" width="66" height="21" rx="4" transform="translate(460 4555)" />
                    <rect className="b" width="66" height="21" rx="4" transform="translate(431 4623)" />
                    <rect className="b" width="18" height="21" rx="4" transform="translate(619 4583)" />
                    <rect className="b" width="18" height="21" rx="4" transform="translate(535 4555)" />
                    <rect className="b" width="18" height="21" rx="4" transform="translate(649 4583)" />
                    <rect className="b" width="18" height="21" rx="4" transform="translate(405 4623)" />
                    <g transform="translate(486.303 4577.673)">
                        <path className="c" d="M24.494,25.124h90.744v90.744H24.494Z" transform="translate(-29.388 70.047) rotate(-45)" />
                        <path className="c" d="M70,82.308V41.475" />
                        <path className="c" d="M69.977,93.975h0" />
                        <path
                            className="c"
                            d="M69.924,93.975a1.429,1.429,0,0,0-1.383,1.487A1.482,1.482,0,0,0,70,96.892h.052A1.435,1.435,0,0,0,71.435,95.4,1.476,1.476,0,0,0,70,93.975"
                        />
                        <path className="c" d="M70,93.975h-.052" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

NewPlanIcon.propTypes = {
    className: PropTypes.string
};

export default NewPlanIcon;
