/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const AbsenceIcon = ({size, className}) => {
    return (
        <SvgIcon style={{fontSize: size}} className={className} viewBox="0 0 24 24">
            <path id="Pfad_6456" data-name="Pfad 6456" d="M0,0H24V24H0Z" fill="none" />
            <path
                id="Pfad_6457"
                data-name="Pfad 6457"
                d="M9.31,17l2.44-2.44L14.19,17l1.06-1.06L12.81,13.5l2.44-2.44L14.19,10l-2.44,2.44L9.31,10,8.25,11.06l2.44,2.44L8.25,15.94ZM19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                fill="#be4517"
            />
        </SvgIcon>
    );
};

AbsenceIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

AbsenceIcon.defaultProps = {
    size: "1rem"
};

export default AbsenceIcon;
