import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        "right": "90px",
        "bottom": "30px",
        "width": "489px",
        "& .MuiAlert-message": {
            fontSize: "0.875rem",
            fontWeight: theme.typography.fontWeightMedium
        }
    },
    success: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light
    },
    error: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light
    },
    info: {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.light
    }
}));
