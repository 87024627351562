import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    tableRoot: {
        height: "100%",
        width: "100%",
        background: theme.palette.background.default
    },
    table: {
        tableLayout: "fixed"
    },
    tableRow: {
        height: "50px",
        transition: "opacity .5s ease-in-out"
    },
    stickyHeader: {
        "&:first-of-type": {
            // in order to make first column of the header sticky
            zIndex: 12,
            left: 0
        }
    },
    sticky: {
        "position": "sticky",
        "left": 0,
        // "backgroundColor": "#fff",
        "&:first-of-type": {
            // in order to make first column sticky
            zIndex: 10
        }
    },
    hidden: {
        opacity: 0
    },
    tableCell: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        height: "inherit",
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "0",
        paddingRight: "0",
        verticalAlign: "top"
    },
    textCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: theme.palette.grey[700],
        padding: "0 8px"
    },
    textCellEmergency: {
        color: theme.custom.emergency.main
    },
    textCellDisabled: {
        color: theme.palette.text.disabled
    },
    emergency: {
        backgroundColor: `${theme.custom.emergency.light} !important`
    },
    allowWrap: {
        overflow: "unset",
        whiteSpace: "pre-wrap"
    },
    cursorPointer: {
        cursor: "pointer"
    },
    errorTable: {
        borderLeft: `5px solid ${theme.palette.error.main}`,
        height: "100%"
    },
    activeSortLabelIcon: {
        "& .MuiTableSortLabel-icon": {
            color: `${theme.palette.primary.main} !important`
        }
    },
    tableSortLabel: {
        "& .MuiTableSortLabel-icon": {
            opacity: 0.6,
            visibility: "visible"
        }
    }
}));
