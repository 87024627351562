/**
 * @fileoverview loading indicator
 */

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {withStyles} from "tss-react/mui";

import styles from "./loading.styles";

/**
 * Loading indicator
 *
 * shows a spinner after a delay (ms)
 */
class Loading extends Component {
    /**
     * constructor
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.timer = null;

        this.state = {
            show: false
        };
    }

    /**
     * lifecycle method
     */
    componentDidMount() {
        const {delay} = this.props;

        this.timer = setTimeout(this.handleShow.bind(this), delay);
    }

    /**
     * lifecycle method
     */
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    /**
     * show spinner
     */
    handleShow() {
        this.setState({
            show: true
        });
    }

    /**
     * render
     * @return {null|jsx}
     */
    render() {
        const {classes, fullScreen} = this.props;
        const {show} = this.state;

        if (!show) {
            return null;
        }

        return (
            <Grid alignItems="center" className={fullScreen ? classes.fullscreen : classes.root} container justifyContent="center">
                <span data-testid="loadingIcon">
                    <CircularProgress />
                </span>
            </Grid>
        );
    }
}

Loading.propTypes = {
    delay: PropTypes.number,
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool
};

Loading.defaultProps = {
    delay: 100
};

export default withStyles(Loading, styles);
