import {Paper} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import {selectShowTeaser} from "../../../redux/events/event_selectors";
import useStyles from "./detail_right.styles";

export const DetailRight = ({open, onClose, children, fullActionMenubar, disableEsc, isFullscreen}) => {
    const {classes, cx} = useStyles();
    const showTeaser = useSelector(selectShowTeaser);

    useEffect(() => {
        if (open && !disableEsc) {
            document.addEventListener("keydown", handleCloseWithEsc, {capture: true});
        }
        return () => {
            if (open && !disableEsc) {
                document.removeEventListener("keydown", handleCloseWithEsc, {capture: true});
            }
        };
    }, [open, disableEsc]);

    const handleCloseWithEsc = (e) => {
        if (e.key === "Escape") {
            onClose();
        }
    };

    return (
        <Paper
            className={cx(classes.root, {
                [classes.showTeaser]: showTeaser,
                [classes.fullActionMenubar]: fullActionMenubar,
                [classes.isFullscreen]: isFullscreen
            })}
            elevation={2}
            id="detail-right"
            style={{display: open ? "flex" : "none"}}
        >
            {children}
        </Paper>
    );
};

DetailRight.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    fullActionMenubar: PropTypes.bool,
    disableEsc: PropTypes.bool,
    isFullscreen: PropTypes.bool
};

export default DetailRight;
