// @ts-check
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Box, Button, Popover, TextField, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import {StaticDatePicker as MaterialDatePicker} from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectLanguage} from "../../../redux/app_selectors";
import {selectShowTeaser} from "../../../redux/events/event_selectors";
import {PERMISSION, useSecurity} from "../../../utils/security";
import CustomPickerDay from "./custom_picker_day";
import useStyles from "./date_picker.styles";

/** @typedef {import("@mui/material").TextFieldProps} TextFieldProps */

/**
 * DatePicker component
 * @param {Object} props
 * @param {DateTimeType} props.selectedDate DateTime (luxon)
 * @param {Function} props.changeDate
 * @param {Boolean} [props.loading]
 * @param {Boolean} [props.disabled] true if the whole interaction of date picker was disabled
 * @return {React.ReactElement|null}
 */
const DatePicker = ({selectedDate, changeDate, loading, disabled}) => {
    const {classes, cx} = useStyles();
    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.up("sm"));
    const {t} = useTranslation();
    const {isGranted} = useSecurity();

    const {now, plusDT, minusDT, format: formatDT, areSame} = useContext(DateContext);

    // redux store
    const language = useSelector(selectLanguage);
    const showTeaser = useSelector(selectShowTeaser);

    // State
    const [anchorEl, setAnchorEl] = useState(null);
    /** @type [String, Function] */
    const [format, setFormat] = useState(DATE_FORMATS.DATE_LONG);

    useEffect(() => {
        if (large) {
            setFormat(DATE_FORMATS.DATE_LONG);
            // setFormat(config.DATE_FORMAT[language] || config.DATE_FORMAT.de);
        } else {
            setFormat(DATE_FORMATS.DATE_SHORT);
            // setFormat(config.DATE_FORMAT_SHORT[language] || config.DATE_FORMAT_SHORT.de);
        }
    }, [language, large]);

    const handleForwardClick = () => changeDate(plusDT(selectedDate, "day", 1));
    const handleBackClick = () => changeDate(minusDT(selectedDate, "day", 1));
    const handleChangeDate = (date) => changeDate(date);
    const handleTodayClick = () => changeDate(now());

    /**
     * handler to open popover (Datepicker)
     * @param {Object} event
     */
    const handleOpenDatePicker = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    /**
     * handler to close popover (Datepicker)
     */
    const handleCloseDatePicker = () => {
        setAnchorEl(null);
    };

    const back = (
        <IconButton
            className={classes.iconButton}
            data-testid="datePickerBack"
            disabled={disabled || loading}
            size="small"
            title="back"
            onClick={handleBackClick}
        >
            <ChevronLeftIcon
                className={cx(classes.icon, {
                    [classes.iconDisabled]: loading || disabled
                })}
            />
        </IconButton>
    );
    const isFutureDisplayAllowed = !isGranted(PERMISSION.VIEW_DAYVIEW_FUTUREOPS) && selectedDate && areSame(selectedDate, now(), "day");
    const forward = (
        <IconButton
            className={classes.iconButton}
            data-testid="datePickerForward"
            disabled={disabled || loading || isFutureDisplayAllowed}
            size="small"
            title="forward"
            onClick={handleForwardClick}
        >
            <ChevronRightIcon
                className={cx(classes.icon, {
                    [classes.iconDisabled]: disabled || loading || isFutureDisplayAllowed
                })}
            />
        </IconButton>
    );
    if (!selectedDate) return null;

    const maxDate = isGranted(PERMISSION.VIEW_DAYVIEW_FUTUREOPS) ? plusDT(now(), "year", config.MAX_DATE_IN_YEARS) : now();
    return (
        <Box
            className={cx(classes.root, {
                [classes.rootWithTeaser]: showTeaser
            })}
        >
            {back}
            <div
                className={cx(classes.dateLabel, {
                    [classes.disabled]: disabled
                })}
                data-testid="datePickerInput"
                role={"button"}
                tabIndex={0}
                onClick={handleOpenDatePicker}
                onKeyDown={(e) => e.key === "Enter" && handleOpenDatePicker(e)}
            >
                {`${formatDT(selectedDate, format)}`}
            </div>
            {forward}
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                classes={{paper: classes.popover}}
                open={Boolean(anchorEl)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                onClose={handleCloseDatePicker}
            >
                <MaterialDatePicker
                    className={classes.staticDatePicker}
                    disableHighlightToday={true}
                    displayStaticWrapperAs="desktop"
                    maxDate={maxDate}
                    minDate={minusDT(now(), "year", config.MIN_DATE_IN_YEARS)}
                    renderDay={(date, selectedDates, pickersDayProps) => (
                        <CustomPickerDay date={date} key={formatDT(date, format)} pickersDayProps={pickersDayProps} />
                    )}
                    // @ts-ignore couldn't solve error if TextField props
                    renderInput={(params /** @param {TextFieldProps} params */) => <TextField variant="standard" {...params} />}
                    value={selectedDate}
                    onChange={handleChangeDate}
                />
                <Button
                    className={classes.today}
                    disabled={areSame(selectedDate, now(), "day")}
                    variant={"text"}
                    onClick={handleTodayClick}
                >
                    {t("DatePicker.today")}
                </Button>
            </Popover>
        </Box>
    );
};

DatePicker.propTypes = {
    selectedDate: PropTypes.object, // initially null, set DateTime from luxon
    changeDate: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

export default DatePicker;
