/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const AllowPrintIcon = ({size = "1rem", className}) => {
    return (
        <SvgIcon style={{fontSize: size}} className={className} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z" fill="#616161"/>
            <path d="M23.0026 3.83301C20.7026 3.83301 18.8359 5.69967 18.8359 7.99967C18.8359 10.2997 20.7026 12.1663 23.0026 12.1663C25.3026 12.1663 27.1693 10.2997 27.1693 7.99967C27.1693 5.69967 25.3026 3.83301 23.0026 3.83301ZM22.1693 10.083L20.0859 7.99967L20.6734 7.41217L22.1693 8.90384L25.3318 5.74134L25.9193 6.33301L22.1693 10.083Z" fill="#616161"/>
        </SvgIcon>

    );
};

AllowPrintIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};


export default AllowPrintIcon;
