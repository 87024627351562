import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    error: {
        fontSize: "0.688rem",
        marginTop: "1rem",
        maxWidth: "fit-content"
    },
    inputCustomFrequency: {
        width: "100%",
        height: "1.5rem",
        fontSize: "0.75rem",
        color: theme.palette.text.primary,
        padding: `${theme.spacing("xxs")} 0`
    },
    menu: {
        fontSize: "0.875rem"
    },
    disableHover: {
        "& .MuiIconButton-root:hover": {
            backgroundColor: theme.palette.grey[300]
        },
        "& .MuiIconButton-root.Mui-disabled": {
            opacity: 0.6,
            backgroundColor: theme.palette.grey[300]
        }
    },
    disableHoverSelected: {
        "& .MuiIconButton-root:hover": {
            background: theme.palette.primary.main
        },
        "& .MuiIconButton-root.Mui-disabled": {
            opacity: 0.6,
            backgroundColor: theme.palette.primary.main
        }
    },
    dateButton: {
        fontSize: "0.75rem",
        width: "2rem",
        height: "2rem",
        lineHeight: "2rem",
        marginLeft: "0.5rem",
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main
    },
    selected: {
        background: "#000",
        border: "1px solid #000",
        color: theme.palette.primary.contrastText
    },
    weekRow: {
        display: "flex",
        padding: "0.5rem 0",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.grey[100]}`
    },
    weekLabel: {
        fontSize: "0.75rem",
        color: theme.palette.grey[700],
        lineHeight: "2rem",
        width: "6rem"
    }
}));
