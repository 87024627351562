// @ts-check
/**
 * @fileoverview custom interval input
 */

import {FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import {Info} from "luxon";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {DateContext} from "../../../contexts/dates";
import usePrevious from "../../../hooks/usePrevious";
import useStyles from "./custom_interval.styles";

/**
 * render CustomInterval component
 *
 * @param {object} props
 * @param {string|null} [props.error]
 * @param {number} props.number
 * @param {Function} props.onChangeNumber
 * @param {Array<Number>} props.repeat
 * @param {Function} props.onChangeRepeat
 * @param {boolean} [props.disabled]
 * @param {DateTimeType} props.startDate
 * @param {boolean} [props.showWeekend]
 * @return {React.ReactElement}
 */
const CustomInterval = ({disabled, error, number, onChangeNumber, repeat, onChangeRepeat, startDate, showWeekend}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {getDT} = useContext(DateContext);

    const prevValue = usePrevious(startDate);
    const [isoWeekday, setIsoWeekday] = useState(getDT(startDate, "weekday"));
    const prevIsoWeekday = usePrevious(isoWeekday);

    // filter only repeat items less than repeatAfterDays (number * 7)
    useEffect(() => {
        const newRepeat = repeat.filter((item) => item < number * 7);
        onChangeRepeat(newRepeat);
    }, [number]);

    useEffect(() => {
        // if start date is changed, reset repeat days
        if (startDate.isValid) {
            setIsoWeekday(getDT(startDate, "weekday"));
        }
        if (
            prevValue &&
            startDate &&
            prevValue.isValid &&
            startDate.isValid &&
            getDT(prevValue, "weekday") !== getDT(startDate, "weekday") &&
            repeat.length > 0
        ) {
            onChangeRepeat([]);
        }
    }, [startDate]);

    // const frequencies = ["weekly"];
    const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    /**
     * Calculate number of selected days within interval (= frequency)
     * ex. Mon, Wed, Fri of the first week and Tue, Thu of second week within 2 weeks interval
     * => 0, 2, 4, 8, 10 will be returned
     * @param {number} i - number of week
     * @param {number} j -
     * @return {number}
     */
    const getAdjustedRepeatNumber = (i, j) => {
        const num = i * 7 + j;
        const adjust = startDate.isValid ? getDT(startDate, "weekday") - 1 : prevIsoWeekday; // 1: Monday, 2: Tuesday...
        return num - adjust < 0 ? num - adjust + number * 7 : num - adjust;
    };

    const handleClick = (i, j) => {
        const selectedDays = cloneDeep(repeat);
        const adjustedNum = getAdjustedRepeatNumber(i, j);
        if (selectedDays.includes(adjustedNum)) {
            selectedDays.splice(selectedDays.indexOf(adjustedNum), 1);
        } else {
            selectedDays.push(adjustedNum);
        }
        selectedDays.sort((a, b) => a - b);
        onChangeRepeat(selectedDays);
    };

    const dateSelect = [];
    for (let i = 0; i < number; i++) {
        const dayButtons = [];
        for (let j = 0; j < 7; j++) {
            const adjustedNum = getAdjustedRepeatNumber(i, j);
            if (!showWeekend && j >= 5) {
                continue;
            } else {
                dayButtons.push(
                    <div
                        className={cx({
                            [classes.disableHover]: !repeat.includes(adjustedNum),
                            [classes.disableHoverSelected]: repeat.includes(adjustedNum)
                        })}
                        key={`date-${i}+${j}`}
                    >
                        <IconButton
                            className={cx(classes.dateButton, {
                                [classes.selected]: repeat.includes(adjustedNum)
                            })}
                            disabled={disabled}
                            size="large"
                            title={Info.weekdays("short")[j]}
                            onClick={() => handleClick(i, j)}
                        >
                            {Info.weekdays("short")[j]}
                        </IconButton>
                    </div>
                );
            }
        }
        dateSelect.push(
            <div className={classes.weekRow} key={`week-${i}`}>
                <span className={classes.weekLabel}>{t("App.nWeek", {num: i + 1})}</span>
                {dayButtons}
            </div>
        );
    }

    const handleChangeNumber = (e) => {
        onChangeNumber(e.target.value);
    };
    return (
        <div style={{width: "100%"}}>
            <FormControl style={{display: "flex"}} variant="standard">
                <InputLabel id="select-discipline-label" shrink>
                    {t("App.weeks")}
                </InputLabel>
                <Select
                    className={classes.inputCustomFrequency}
                    disabled={disabled}
                    error={Boolean(error)}
                    value={number}
                    variant="standard"
                    onChange={handleChangeNumber}
                >
                    {numberArray.map((item) => (
                        <MenuItem className={classes.menu} key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
                {error && (
                    <FormHelperText className={classes.error} error>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
            <div style={{marginTop: "1rem"}}>{dateSelect}</div>
        </div>
    );
};

CustomInterval.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    number: PropTypes.number.isRequired,
    onChangeNumber: PropTypes.func.isRequired,
    repeat: PropTypes.arrayOf(PropTypes.number),
    onChangeRepeat: PropTypes.func.isRequired,
    startDate: PropTypes.object.isRequired, // DateTime object
    showWeekend: PropTypes.bool
};

CustomInterval.defaultProps = {
    classes: {}
};

export default CustomInterval;
