// @ts-check

import {arrayOf, shape, string} from "prop-types";
import React, {useContext} from "react";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "./date_list.styles";

/**
 * The list on "Next Dates" in the TimeslotDialog component
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Array<{end: string, slotId: (string|null), start: string}>} props.list
 * @return {React.ReactElement}
 */
const DateList = ({className, list}) => {
    const {classes, cx} = useStyles();
    const {formatFromISO} = useContext(DateContext);

    return (
        <ul className={cx(classes.root, className)}>
            {list.map(({start, end}) => (
                <li className={classes.item} key={`${start}-${end}`}>
                    <span>{`${formatFromISO(start, DATE_FORMATS.DATE)} / ${formatFromISO(start, DATE_FORMATS.TIME)}`}</span>
                    <span className={classes.separator}>-</span>
                    <span>{formatFromISO(end, DATE_FORMATS.TIME)}</span>
                </li>
            ))}
        </ul>
    );
};

DateList.propTypes = {
    className: string,
    list: arrayOf(
        shape({
            start: string.isRequired,
            end: string.isRequired,
            slotId: string
        })
    ).isRequired
};

export default DateList;
