// @ts-check
import config from "../../../../config/config.json";
import {diffDateTime} from "../../../utils/luxon_helpers";

/**
 * format the duration
 *     if -1 (start it not set) return hyphen
 *     if the duration is more than max minutes, return 999+
 *     else the duration minutes in string
 *
 * @param {number} duration the duration in minutes, if the dtStart is not set, -1
 * @return {string}
 */
export const formatCpbDuration = (duration) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN},
        MAX_CPB_DURATION_MINUTES
    } = config;
    if (duration === -1) return HYPHEN;
    return duration >= MAX_CPB_DURATION_MINUTES ? `${MAX_CPB_DURATION_MINUTES.toString()}+` : duration.toString();
};
/**
 * Calculate the duration of CPB
 *
 * @param {DateTimeType} start
 * @param {DateTimeType} end
 * @param {DateTimeType} now
 * @return {number}
 */
export const calculateCpbDuration = (start, end, now) => {
    if (!start) return -1;
    return Math.floor(diffDateTime(end || now, start, "minutes"));
};

export const formatCpbDurationAndEndTime = () => {};
