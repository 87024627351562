const styles = () => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignSelf: "center"
    },
    fullscreen: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignSelf: "center"
    }
});

export default styles;
