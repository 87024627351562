import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        listStyleType: "none",
        margin: 0,
        marginBottom: theme.spacing("m"),
        padding: 0
    },
    item: {
        "fontSize": "0.75rem",
        "color": theme.palette.grey[900],
        "marginTop": theme.spacing("xxs"),

        "&:first-of-type": {
            marginTop: 0
        }
    },
    separator: {
        margin: `0 ${theme.spacing("xxs")}`
    }
}));
