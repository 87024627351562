import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        position: "fixed",
        top: "3rem",
        right: theme.spacing("xxl"),
        margin: 0,
        height: "calc(100% - 3rem)",
        backgroundColor: theme.custom.background.layer,
        zIndex: theme.custom.zIndex.canvas + 130,
        width: "500px"
    },
    showTeaser: {
        top: "78px",
        height: "calc(100% - 78px)"
    },
    fullActionMenubar: {
        right: "148px"
    },
    isFullscreen: {
        right: "0px"
    }
}));
