// @ts-check
import {bool, object, string} from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {calculateCpbDuration, formatCpbDuration} from "./helpers";

/**
 * render CPB component
 * @param {Object} props
 * @param {CpbTimestamps} [props.duraCpb]
 * @param {string} [props.styles]
 * @param {boolean} [props.isLabelVisible=true]
 * @param {boolean} [props.isDurationBold=true]
 * @return {React.ReactElement}
 */
const Cpb = ({duraCpb, styles, isLabelVisible = true, isDurationBold = true}) => {
    const {fromISO, formatFromISO, now} = useContext(DateContext);
    const {t} = useTranslation();

    const {dtStart, dtEnd} = duraCpb || {};
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    const duration = formatCpbDuration(calculateCpbDuration(dtStart && fromISO(dtStart), dtEnd && fromISO(dtEnd), now()));
    const time = dtEnd && formatFromISO(dtEnd, DATE_FORMATS.TIME);

    const durationJSX = isDurationBold ? <strong>{duration}</strong> : <>{duration}</>;
    const text = dtStart ? `${duration} ${t("Cpb.min")}` + (dtEnd ? `, ${t("Cpb.done", {time})}` : "") : "";
    return (
        <span className={styles} data-testid={"cpb"} title={text}>
            {isLabelVisible && <>{t("Cpb.label")}&nbsp;</>}
            {dtStart ? durationJSX : ` ${HYPHEN} `}&nbsp;
            {dtStart && t("Cpb.min")}
            {dtEnd && `, ${t("Cpb.done", {time})}`}
        </span>
    );
};
Cpb.propTypes = {
    duraCpb: object,
    styles: string,
    isLabelVisible: bool,
    isDurationBold: bool
};
export default Cpb;
