import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    newPlan: {
        "& .a": {
            fill: theme.palette.common.white,
            opacity: 0
        },
        "& .b": {
            fill: theme.palette.primary.main,
            opacity: 0.2
        },
        "& .c": {
            fill: "none",
            stroke: theme.palette.primary.main,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "3px"
        }
    }
}));
