import {makeStyles} from "tss-react/mui";
/**
 * @todo #14788: theme.typography throws error, to be fixed
 * @type {object}
 */
export default makeStyles()((theme) => ({
    searchTextWrapper: {
        width: "100%",
        height: "50px",
        padding: "0.25rem 1rem"
    },

    searchInput: {
        height: "36px"
    }
}));
