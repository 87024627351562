import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        "position": "absolute",
        "backgroundColor": theme.custom.background.layer,
        "& .MuiIconButton-root:hover": {
            backgroundColor: theme.custom.background.layer
        },
        "& .MuiDialogActions-root": {
            padding: "0 1.5rem",
            justifyContent: "flex-start"
        },
        "& .MuiDialogContent-root": {
            overflowY: "visible",
            padding: `${theme.spacing("s")} 1.5rem 1.5rem`
        },
        "overflow": "visible"
    },
    iconButton: {
        "position": "absolute",
        "top": "2.5rem",
        "right": "-1.5rem",
        "backgroundColor": theme.custom.background.layer,
        "borderTopLeftRadius": 0,
        "borderBottomLeftRadius": 0,
        "zIndex": theme.custom.zIndex.canvas + 135,
        "& .MuiSvgIcon-root": {
            fontSize: "1.25rem"
        },
        "borderRight": "1px solid rgba(0, 0, 0, 0.2)"
    },
    title: {
        "& h2": {
            fontSize: "1.125rem",
            letterSpacing: "-0.15px",
            lineHeight: "1.125rem",
            fontWeight: theme.typography.fontWeightLight,
            color: theme.palette.grey[900],
            textTransform: "uppercase"
        }
    },
    icon: {
        top: "0.4rem",
        position: "relative",
        marginRight: theme.spacing("xs")
    },
    message: {
        fontSize: "0.75rem",
        fontWeight: theme.typography.fontWeightLight
    }
}));
