/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";


import useStyles from "./new_plan_discarded.styles";

const NewPlanDiscardedIcon = ({className}) => {
    const {classes, cx} = useStyles();
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" className={cx(classes.newPlanDiscarded, className)} viewBox="0 0 262 170.667">
            <g transform="translate(-405 -4353)">
                <rect className="a" width="66" height="21" rx="4" transform="translate(460 4353)" />
                <rect className="a" width="66" height="21" rx="4" transform="translate(431 4421)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(619 4381)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(535 4353)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(649 4381)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(405 4421)" />
                <rect className="a" width="51" height="21" rx="4" transform="translate(493 4381)" />
                <rect className="a" width="51" height="21" rx="4" transform="translate(556 4381)" />
                <g transform="translate(214 378)">
                    <path
                        className="b"
                        d="M55.417,137.083H8.75a5.833,5.833,0,0,1-5.833-5.833V8.75A5.833,5.833,0,0,1,8.75,2.917H86.292a5.833,5.833,0,0,1,4.123,1.708L112.041,26.25a5.833,5.833,0,0,1,1.709,4.124V49.583"
                        transform="translate(272.083 4007.083)"
                    />
                    <g transform="translate(336.083 4072.083)">
                        <path
                            className="b"
                            d="M71.4,20.417V77.4a3,3,0,0,1-3,3H23.416a3,3,0,0,1-3-3V20.417"
                            transform="translate(-8.502 -8.502)"
                        />
                        <path className="b" d="M43.75,11.914v-6a3,3,0,0,1,3-3H67.744a3,3,0,0,1,3,3v6" transform="translate(-19.839 0)" />
                        <path className="b" d="M2.917,20.417H71.9" transform="translate(0 -8.502)" />
                        <path className="b" d="M43.75,40.833V76.824" transform="translate(-19.839 -18.422)" />
                        <path className="b" d="M70,40.833V76.824" transform="translate(-32.592 -18.422)" />
                        <path className="b" d="M96.25,40.833V76.824" transform="translate(-45.346 -18.422)" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

NewPlanDiscardedIcon.propTypes = {
    className: PropTypes.string
};

export default NewPlanDiscardedIcon;
