// @ts-check
import {useTheme} from "@mui/material";
import {PickersDay, pickersDayClasses} from "@mui/x-date-pickers";
import {object} from "prop-types";
import React from "react";

import useStyles from "./date_picker.styles";

/** @typedef {import("@mui/x-date-pickers").PickersDayProps} PickersDayProps */

/**
 * CustomPickerDay component
 * @param {Object} props
 * @param {Date} props.date
 * @param {PickersDayProps} props.pickersDayProps
 * @return {React.ReactElement}
 */
const CustomPickerDay = ({date, pickersDayProps}) => {
    const {classes, cx} = useStyles();
    const {primary} = useTheme().palette;

    if (!date) {
        return <PickersDay {...pickersDayProps} />;
    }
    return (
        <div className={classes.dayWrapper}>
            {
                <PickersDay
                    {...pickersDayProps}
                    sx={{
                        [`&&.${pickersDayClasses.selected}`]: {
                            backgroundColor: primary.main
                        }
                    }}
                />
            }
            {pickersDayProps.today && !pickersDayProps.outsideCurrentMonth && (
                <span
                    className={cx(classes.todayDot, {
                        [classes.todayDotSelected]: pickersDayProps.selected
                    })}
                />
            )}
        </div>
    );
};

CustomPickerDay.propTypes = {
    date: object.isRequired,
    pickersDayProps: object.isRequired // PickersDayProps
};

export default CustomPickerDay;
