// @ts-check
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {getComparator, stableSort} from "../../../utils/stable_sort";
import useStyles from "./data_table.styles";

/**
 * A reusable Table to be used across all our components. It implement the material-ui table
 * with extra functionality and styling.
 *
 * @param {object} props
 * @param {Array<DataTableLabel>} props.labels
 * @param {Array<object>} props.data An array of object to be rendered, id is used to set testid and onClick handler, key is used to set key, display is used to display element
 * @param {Function} [props.onSetPage] A handler to set the table page. required if isPaginationVisible=true
 * @param {number} [props.page = 0] The current table page. required if isPaginationVisible=true
 * @param {number} [props.tableWidthProps] The width of the table. This property has precedence over tableLeftRightPadding
 * @param {number} [props.tableLeftRightPadding] The distance from the left of the screen to calculate table width. ignored if tableWidthProps is set
 * @param {number} [props.tableTopBottomPadding] The padding to calculate table height
 * @param {Array<string>} [props.disableSortColumns] A list with the names of the columns that are not sortable
 * @param {string} [props.defaultSorted] The name of the column that is sorted by default
 * @param {("asc"|"desc")} [props.defaultOrder="asc"] The order in which the defaultSorted column should be sorted
 * @param {Boolean} [props.isPaginationVisible=true] If false, deactivate pagination
 * @param {React.ReactElement} [props.extraHeader] extra header between header and content
 * @param {Function} [props.onRowClick]
 * @param {Boolean} [props.isErrorTable] true if the whole table is error data. The border-left of each rows is styled with red
 * @param {Boolean} [props.hasMaxHeight] true if the maxHeight should be shown
 * @param {Boolean} [props.hasStickyPagination = false]
 * @return {React.ReactElement}
 */
const DataTable = ({
    labels,
    data,
    disableSortColumns,
    tableWidthProps,
    tableLeftRightPadding,
    tableTopBottomPadding = 300,
    page = 0,
    onSetPage,
    defaultSorted,
    defaultOrder,
    isPaginationVisible = true,
    extraHeader,
    onRowClick,
    isErrorTable,
    hasMaxHeight = true,
    hasStickyPagination = false
}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState(defaultSorted);
    const [order, setOrder] = useState(defaultOrder || "asc");
    const [tableWidth, setTableWidth] = useState(tableWidthProps || window.innerWidth - tableLeftRightPadding);
    // eslint-disable-next-line no-unused-vars
    const [maxHeight, setMaxHeight] = useState(window.innerHeight - tableTopBottomPadding);

    useEffect(() => {
        window.addEventListener("resize", resetMaxHeight);
        return () => {
            window.removeEventListener("resize", resetMaxHeight);
        };
    }, []);

    useEffect(() => {
        resetMaxHeight();
    }, [tableTopBottomPadding]);

    const resetMaxHeight = () => {
        if (!tableWidthProps) {
            setTableWidth(window.innerWidth - tableLeftRightPadding);
        }
        setMaxHeight(window.innerHeight - tableTopBottomPadding);
    };

    const handleChangePage = (event, newPage) => {
        onSetPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        onSetPage(0);
    };
    const createSortHandler = (property) => () => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const sortedData = useMemo(() => (orderBy ? stableSort(data, getComparator(order, orderBy)) : data), [data, order, orderBy]);
    const shownData = isPaginationVisible ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedData;

    const isClickHandlerSet = typeof onRowClick === "function";
    return (
        <>
            <TableContainer
                style={{maxHeight: hasMaxHeight ? `${maxHeight}px` : null, overflowY: hasStickyPagination ? "auto" : undefined}}
            >
                <Table className={classes.table} stickyHeader style={{width: tableWidth + "px"}}>
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            {labels.map(({id, isSticky, width, label}) => (
                                <TableCell
                                    className={cx({[classes.stickyHeader]: isSticky, [classes.stickyHeader]: isSticky})}
                                    key={id}
                                    style={{width: width}}
                                >
                                    <TableSortLabel
                                        active={orderBy === id}
                                        className={cx(classes.tableSortLabel, {[classes.activeSortLabelIcon]: orderBy === id})}
                                        direction={orderBy === id ? order : "asc"}
                                        disabled={disableSortColumns && disableSortColumns.includes(id)}
                                        hideSortIcon={disableSortColumns && disableSortColumns.includes(id)}
                                        onClick={createSortHandler(id)}
                                    >
                                        {label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                        {extraHeader}
                    </TableHead>
                    <TableBody>
                        {shownData.map((row) => {
                            const id = row.id || row._id;
                            return (
                                <TableRow
                                    className={cx(classes.tableRow, {
                                        [classes.hidden]: row.isHidden,
                                        [classes.cursorPointer]: isClickHandlerSet
                                    })}
                                    data-testid={id}
                                    hover={true}
                                    key={row.key || row.id || row._id}
                                    onClick={(e) => onRowClick?.({currentTarget: e?.currentTarget, id: row.id || row._id})}
                                >
                                    {labels.map((label, index) => {
                                        const maxWidth =
                                            label.width.includes("px") || label.width.includes("rem")
                                                ? label.width
                                                : tableWidth * (parseInt(label.width.replace("%", ""), 10) / 100) + "px";
                                        return (
                                            <TableCell
                                                className={cx(classes.tableCell, {
                                                    [classes.emergency]: row.isEmergency,
                                                    [classes.sticky]: label.isSticky
                                                })}
                                                key={`${row.id}-${label.id}`}
                                                width={label.width}
                                            >
                                                <div
                                                    className={cx(classes.textCell, {
                                                        [classes.textCellEmergency]: row.isEmergency,
                                                        [classes.textCellDisabled]: row.isDeactivated,
                                                        [classes.allowWrap]: label.allowWrap,
                                                        [classes.errorTable]: isErrorTable && index === 0
                                                    })}
                                                    style={{maxWidth: maxWidth}}
                                                    title={(label.setTitle && row.tooltip?.[label.id]) || ""}
                                                >
                                                    {row.display[label.id]}
                                                </div>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPaginationVisible && (
                <TablePagination
                    component="div"
                    count={data.length}
                    labelRowsPerPage={t("App.rowsPerPage")}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 15, 25, 50]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </>
    );
};

DataTable.propTypes = {
    data: PropTypes.array.isRequired, // has properties defined in labels[*].id, display, isHidden
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
            width: PropTypes.string.isRequired
        })
    ),
    disableSortColumns: PropTypes.arrayOf(PropTypes.string),
    tableWidthProps: PropTypes.number, // if tableWidthProps is set, tableLeftRightPadding is ignored
    tableLeftRightPadding: PropTypes.number, // used to calculate table width
    tableTopBottomPadding: PropTypes.number,
    page: PropTypes.number, // is optional, if isPaginationVisible is false
    onSetPage: PropTypes.func, // is optional, if isPaginationVisible is false
    defaultSorted: PropTypes.string,
    defaultOrder: PropTypes.string,
    isPaginationVisible: PropTypes.bool,
    extraHeader: PropTypes.node,
    onRowClick: PropTypes.func,
    isErrorTable: PropTypes.bool,
    hasMaxHeight: PropTypes.bool,
    hasStickyPagination: PropTypes.bool
};

export default DataTable;
