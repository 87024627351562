// @ts-check
import {Dialog} from "@mui/material";
import {bool, func, node, shape, string} from "prop-types";
import React from "react";

import useStyles from "./detail_dialog.styles";

// (event: {}, reason: "backdropClick" | "escapeKeyDown") => void'
/**
 * The callback function called on click of the manage button.
 *
 * @callback OnClose
 * @param {Object} event
 * @param {"backdropClick" | "escapeKeyDown"} reason
 */

/**
 * Render DetailDialog
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {OnClose} props.onClose
 * @param {{root: string}} [props.styles]
 * @param {React.ReactElement} props.children
 * @param {boolean} [props.isBlockscreenVisible]
 * @return {React.ReactElement}
 */
export const DetailDialog = ({open, onClose, styles, children, isBlockscreenVisible}) => {
    const {classes, cx} = useStyles();

    return (
        <Dialog
            classes={{
                root: cx({
                    [classes.isBlockscreenVisible]: isBlockscreenVisible
                })
            }}
            open={open}
            PaperProps={{
                classes: {root: cx(classes.root, styles?.root)},
                id: "detail-dialog"
            }}
            onClose={onClose}
        >
            {children}
        </Dialog>
    );
};

DetailDialog.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
    styles: shape({
        root: string
    }),
    children: node.isRequired,
    isBlockscreenVisible: bool
};

export default DetailDialog;
