// @ts-check
import {IconButton} from "@mui/material";
import {Info} from "luxon";
import {array, bool, func, number} from "prop-types";
import React from "react";

import useStyles from "../../custom_interval/custom_interval.styles";

/**
 *
 * @param {object} props
 * @param {boolean} [props.disabled]
 * @param {number} props.adjustedNum
 * @param {number} props.i
 * @param {number} props.j
 * @param {Array<number>} props.frequency
 * @param {Function} props.handleClick
 * @return {React.ReactElement}
 */
export const DayButton = ({frequency, i, j, adjustedNum, handleClick, disabled}) => {
    const {classes, cx} = useStyles();

    return (
        <div
            className={cx({
                [classes.disableHover]: !frequency.includes(adjustedNum),
                [classes.disableHoverSelected]: frequency.includes(adjustedNum)
            })}
        >
            <IconButton
                className={cx(classes.dateButton, {
                    [classes.selected]: frequency.includes(adjustedNum)
                })}
                disabled={disabled}
                size="large"
                title={Info.weekdays("short")[j]}
                onClick={() => handleClick(i, j)}
            >
                {Info.weekdays("short")[j]}
            </IconButton>
        </div>
    );
};
DayButton.propTypes = {
    frequency: array.isRequired,
    i: number.isRequired,
    j: number.isRequired,
    adjustedNum: number.isRequired,
    handleClick: func.isRequired,
    disabled: bool
};
