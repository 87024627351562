/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";


import useStyles from "./new_plan_saved.styles";

const NewPlanSavedIcon = ({className}) => {
    const {classes, cx} = useStyles();
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" className={cx(classes.newPlanSaved, className)} viewBox="0 0 262 171">
            <g transform="translate(-1213 -4353)">
                <rect className="a" width="66" height="21" rx="4" transform="translate(1268 4353)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(1427 4381)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(1343 4353)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(1457 4381)" />
                <rect className="a" width="18" height="21" rx="4" transform="translate(1213 4421)" />
                <rect className="a" width="51" height="21" rx="4" transform="translate(1301 4381)" />
                <rect className="a" width="51" height="21" rx="4" transform="translate(1364 4381)" />
                <rect className="a" width="66" height="21" rx="4" transform="translate(1239 4421)" />
                <g transform="translate(369 4036)">
                    <g className="b" transform="translate(927 352)">
                        <circle className="d" cx="68" cy="68" r="68" />
                        <circle className="e" cx="68" cy="68" r="66.5" />
                    </g>
                    <path className="c" d="M-3807.72,444.512l16.336,16.336,41.745-41.746" transform="translate(4775 -20)" />
                </g>
            </g>
        </SvgIcon>
    );
};

NewPlanSavedIcon.propTypes = {
    className: PropTypes.string
};

export default NewPlanSavedIcon;
