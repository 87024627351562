import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    wrapper: {
        color: theme.palette.primary.main,
        fontSize: "0.875rem",
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    removeIcon: {
        marginLeft: theme.spacing("s"),
        fontSize: "1rem"
    },
    disabled: {
        color: theme.palette.text.disabled,
        cursor: "default"
    }
}));
